@import url(https://fonts.googleapis.com/css2?family=Monoton&family=Press+Start+2P&family=Sigmar+One&family=Syne&family=Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Roboto+Mono:wght@300;400&display=swap);

/*
font-family: 'Monoton', cursive;
font-family: 'Press Start 2P', cursive;
font-family: 'Sigmar One', cursive;
font-family: 'Syne', sans-serif;
font-family: 'Vidaloka', serif;
font-family: 'Noto Sans JP', sans-serif;
font-family: 'Roboto Mono', monospace;
*/

body {
  overflow-x: hidden;
}

*::-webkit-scrollbar {
display: none;
}



/* Hide scrollbar for IE, Edge and Firefox */
* {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
margin: 0;
}

.App {
  height: 100vh;
  width: 100vw;
  background-color: #BBB;
  overflow-x: hidden;
}

.Body {
  height: 100vh;
  width: 88vw;
  margin: 0 auto;
}

.topBar {
  left: 0;
  background-color: #DDD;
  height: 9%;
  width: 100vw;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  z-index: 2;
  box-shadow: 0px 0px 25px black;
}


/*
font-family: 'Monoton', cursive;
font-family: 'Press Start 2P', cursive;
font-family: 'Sigmar One', cursive;
font-family: 'Syne', sans-serif;
font-family: 'Vidaloka', serif;
font-family: 'Noto Sans JP', sans-serif;
font-family: 'Roboto Mono', monospace;
*/

.navItem {
  color: black;
  font-family: 'Press Start 2P', cursive;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  display: inline-block;
  margin-top: 30px;
  padding: 5px;
  text-align: center;
  /* padding-right: 10px;
  padding-left: 10px;
  margin-right: 35px; */
}

.navItem:hover {
  cursor: pointer;
  border-style: solid;
  box-shadow: 10px 5px black;
}

.pIcon svg {
  /* border: 2px solid black; */
  float: right;
  margin-right: 40px;
}

.pIcon svg:hover {
  color: yellow;

  transition-duration: 0.1s;
}

.navItem:active {
  color: black;
  background-color: white;

  -ms-user-select: none;

      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.unselectable {
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  pointer-events: none; /* REMOVE IF BAD ON NON-CHROME BROWSERS */
}

.section {
  width: 100%;
  padding-bottom: 45px;
  margin-bottom: 10px;
}

.iconsHolder {
  height: 100%;
  margin-top: 15px;
}

.iconsHolder a {
  color: #AAA;
  padding: 0px 15px 0 15px;
}

.iconsHolder a:hover {
  transition: all 0.25s linear;
  color: white;
}

.title {
  font-family: 'Press Start 2P', cursive;
  font-family: 'Roboto Mono', monospace;
  padding-top: 150px;
  text-align: center;
}

.intro img {
  height: 300px;
  width: 300px !important;
  border-radius: 9999px;
  float: right;
}

.black {
  background-color: rgb(0 81 77);
}
.black2 {
  background-color: rgb(81, 0, 0);
}
.black3 {
  background-color: rgb(81, 66, 0);
}

.blue {
  background-color: rgba(0,0,0,0.7);
}

.lightgray {
  background-color: rgba(255,255,255,0.3);
}

.orange {
  background-color: rgba(116, 181, 119, 0.9);
}

.green {
  background-color: rgba(235, 149, 63, 0.9);
}

.section {
  color: white;
  font-size: 50px;
  text-shadow: 2px 2px 0px black;

}

div > .intro {
  /* color: red; */

}

.chunkTitle {
  font-size: 26px;
  color: #EEE;
  text-align: center;
  /* font-family: 'Monoton', cursive; */
  /* font-family: 'Press Start 2P', cursive; */
  /* font-family: 'Sigmar One', cursive; */
  /* font-family: 'Syne', sans-serif; */
  font-family: 'Press Start 2P', cursive;
  font-family: 'Roboto Mono', monospace;
  margin-bottom: 20px;
}

.chunkEntry {
  font-size: 16px;
  text-shadow: 0px 0px 0px;
  padding: 10px;
  padding-bottom: 5px;

  margin: 10px 0 10px 0px;

	-webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black;
}

.chunkEntry:hover {
  cursor: pointer;
}

.chunkEntry:active {

}

.blueChunk {
  background-image: url(/static/media/blueChunk1.c1065f27.png);
  background-size: cover;
}

.yellowChunk {
  background-image: url(/static/media/yellowChunk.058f2d73.png);
  background-size: cover;
}

.silverChunk {
  background-image: url(/static/media/silverChunk.06b51fd8.png);
  background-size: cover;
}

.blackChunk {
  background-image: url(/static/media/blackChunk.265688f1.png);
  background-size: cover;
}

.pinkChunk {
  background-image: url(/static/media/pinkChunk.fe8ca600.png);
  background-size: cover;
}

.orangeChunk {
  background-image: url(/static/media/orangeChunk.b8b9d7d8.png);
  background-size: cover;
}

.MuiGrid-grid-xs-3 img {
  width: 10px;
}

.MuiGrid-grid-xs-2 img {
    /* height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; */
    height: 100%;
    margin-left: 5px;
}

.marTop {
  margin-top: 10px;
}

.entries {
  padding: 5px;
  color: black;
  background-color: rgba(255,255,255, 1);
  border-radius: 2px;
  margin-left: 10px;
}

.entries .MuiGrid-container .MuiGrid-grid-xs-6 {
  border-style: solid;
  border-color: black;
  border-width: 0.5px;
  padding: 2.5px;
}

/*
font-family: 'Monoton', cursive;
font-family: 'Press Start 2P', cursive;
font-family: 'Sigmar One', cursive;
font-family: 'Syne', sans-serif;
font-family: 'Vidaloka', serif;
font-family: 'Noto Sans JP', sans-serif;
font-family: 'Roboto Mono', monospace;
*/

.desc_header {
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  font-size: 24px;
  margin-bottom: 5px;
  background-color: black;
}


.body_desc {
  font-family: 'Noto Sans JP', sans-serif;
  word-spacing: 5px;
  font-size: 18px;
  text-align: center;
  padding: 0 75px 0 75px;
  text-shadow: 1px 1px 0px black;
}


/* .GMlogo {
  background-image: url(./media/GM-logo.png);
  background-size: cover;
  width: 100%;
  height: 100%;
} */

.jobDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.marBot {
  padding-top: 80px;
  margin-bottom: 60px;
}

.noPadd {
  padding-top: 10px;
}

.mini {
  padding: 25px;
  background-size: auto;
}

.projects {
  font-family: 'Press Start 2P', cursive;
  font-size: 25px;
}

.bigMarBot {
  margin-bottom: 50px;
}

.bigTile {
  font-size: 64px;
  width: 90%;
  background-color: rgba(255,0,0,0.8);
  margin: 0 auto;
  padding-left: 25px;
  padding-top: 30px;
  padding-right: 15px;
  margin-right: 20px;

  border: 10px solid white;
  border-radius: 10px;
}

#root > div > div.Body > div.section.purple > div.MuiGrid-root.MuiGrid-container > div:nth-child(3) {
  margin-top: 40px;
}

.contents .MuiGrid-root {
  height: 100%;
}

.thinTile {
  height: 100px;
  width: 90%;
  background-color: rgba(0,100,255,0.7);
  padding: 15px;
  margin-bottom: 7.5px;
  border: 3.5px solid white;
  border-radius: 3.5px;

  overflow: hidden;
}

.projectImgs {
  height: 100%;
}

.projectImgs img {
  width: 85%;

}

.line {
  background-color: white;
  width: 33%;
  height: 2px;
  margin: 15px 0 15px 0;
}

.projects:hover {
  cursor: pointer;
}

#smallerTitle {
  font-size: 30px;
}

.smallText {
  font-size: 16px;
}

.medText {
  font-size: 14px;
  line-height: 1.5;
}

.tileTitle {
  text-decoration: none;
  color: white;
}

.tileTitle:hover {
  color: yellow;
  transition-duration: 0.1s;
}

table {
  font-family: 'Press Start 2P', cursive;
  font-size: 30px;
}

.tableHolder table {
  margin: 0 auto;
  background-color: rgba(255,255,255,0.3);
  padding: 40px;
  border: 10px solid white;
}

tr th {
  padding-right: 100px;
}

th {
  text-align: left;
  border-bottom: 2px solid white;
  padding-bottom: 25px;
}

table td {
  text-align: left;
  font-size: 18px;
  padding-top: 25px;
}

th:nth-of-type(2) {
  /* border-right: 2px solid white;
  border-left: 2px solid white; */
}

td img {
  width: 70px;
  margin-right: 10px;
}

.mobile {
  display: none;
}

.dialogue {
  font-family: 'Press Start 2P', cursive;
  padding: 35px;
  font-size: 18px;
  line-height: 2;

  color: lime;
  background-color: black;
  padding: 15px;
}

.dialogue i {
  border: 2px solid yellow;
  padding: 7px;
}

.navItem a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 600px) {
/* start of phone styles */

  body {
    overflow-x: hidden;
  }

  *::-webkit-scrollbar {
  display: none;
  }



  /* Hide scrollbar for IE, Edge and Firefox */
  * {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .Body {
    width: 100%;
  }

  .title {
    margin: 0 auto;
    width: 90%;
    font-size: 21px;
  }

  #smallerTitle {
    font-size: 15px;
    letter-spacing: -2px;
  }

  .unselectable img {
    width: 90%;
    height: 90%;
  }

  .iconsHolder {
    margin-top: 18.5px;
  }

  .iconsHolder a {
    padding: 0 20px 0 0;
  }

  .iconsHolder a svg {
    width: 12.5%;
      height: 5%;
  }

  .section {
    padding-bottom: 25px;
  }

  .chunkTitle {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.3;
    height: 20px;
  }

  .mTitle {
    font-size: 18px;
    line-height: 1.5;
  }

  .marBot {
    padding-top: 40px;
  }

  .smallText {
    font-size: 8px;
    margin-top: 0;
  }

  .chunkEntry {
    margin: 0 auto;
    margin-bottom: 15px;
    text-shadow: 2px 2px 2px black;
    text-align: center;
    font-weight: bolder;
    height: 160px;
    padding-bottom: 10px;
  }

  .chunkPic {
    width: 130px;
  }

  .chunkEntry img {
    width: 130px;
    height: 130px;
  }

  .entries {
    background-color: rgba(0,0,0,0);
    text-shadow: 1px 1px 3px black;
    color: white;
    padding: 0;
    font-size: 14px;
  }

  .entries b {
    display: block;
    text-shadow: 0px 0px black;
    color: black;
  }

  .picPadd {
    padding-top: 20px;
  }

  .gmPic {
    height: 100%;
  }

  .descPadd {
    padding-top: 5px;
  }

  .gmPic img {
    height: 120px;
    width: 120px;
    margin-bottom: 15px;
  }

  .entries .MuiGrid-container .MuiGrid-grid-xs-12 {
    border: 1px solid black;
  }

  .tableHolder table {
    margin: 0 auto;
    background-color: rgba(255,255,255,0.3);
    padding: 5px;
    border: 4.8px solid red;
  }

  td img {
    width: 25px;
    margin-right: 10px;
  }

  th {
    font-size: 12px;
    padding-bottom: 5px;
    text-align: center;
  }

  table td {
    font-size: 11px;
    padding-top: 4px;
    border: 1px solid white;

  }

  tr th {
    padding-right: 2.5px;
  }

  .tableHolder {
    margin-top: 15px;
  }

  .App {

  }

  .tiltHolders {
    /* width: 50vw;
    height: 50vh;
    overflow: hidden; */
  }

  .tileTitle {
    font-size: 16px;
  }

  .navItem {
    font-size: 10px;
    margin-top: 20px;
  }

  .navItem:hover {
    border-style: solid;
    box-shadow: 0px 0px white;
  }

  .hamburger {
    background-color: #444;
    transition: 0.5s;
    width: 75%;
    padding: 11px;
  }

  .hamburger svg {
    color: white;
  }

  .ingredients {
    width: 110px;
    padding: 15px;
    padding-left: 0;
  }

  .hamburger:active {
    transform: scale(1.3);
    border-radius: 10px;
    background-color: #777;
  }

  .ingredients {
    background-color: #555;
  }

  .ingredients .navItem {
    background-color: #000;
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .navItem:first-of-type {
    margin-top: 0;
  }

  .navItem:active {
    color: lime;
  }

  .mNav {
    margin-top: 20px !important;
  }

  .dialogue {
    font-family: 'Press Start 2P', cursive;
    padding: 35px;
    font-size: 12px;
    line-height: 2;

    color: lime;
    background-color: black;
    padding: 15px;
  }

  .dialogue i {
    border: 2px solid yellow;
    padding: 5px;
  }

  .navItem a {
    text-decoration: none;
    color: white;
  }

}

@media screen and (min-width: 1921px) {
  .Body {
    width: 90vw !important;
  }
}
@media screen and (max-width: 1441px) {
  .Body {
    width: 100vw !important;
  }
}
